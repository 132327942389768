import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ClassicLayout } from '../../modules/layout/components/classic-layout';
import { ApiClientFactory } from '../../modules/common/services/api-client-factory';

const AccountPage: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isAuthenticated) {
    ApiClientFactory()
      .get('/')
      .then((r) => console.log(r.data));
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <ClassicLayout class="login">
      {isAuthenticated && (
        <>
          <img src={user.picture} />
          <p>User: {JSON.stringify(user)}</p>
        </>
      )}
    </ClassicLayout>
  );
};

export default withAuthenticationRequired(AccountPage);
