import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
// import { AppDispatch, RootState } from '../../../state/Store';
import { ApiErrorResponse } from './api-error-response';
import { ApiErrorHandler, DefaultApiErrorHandlers } from './api-error-handlers';

export function ApiClientFactory(
  // dispach: AppDispatch,
  // getState: () => RootState,
  customErrorHandlers: Map<number, ApiErrorHandler> = new Map()
): AxiosInstance {
  const instance = axios.create({
    baseURL: 'http://localhost:4000/',
    responseType: 'json',
    withCredentials: true
  });

  const { getAccessTokenSilently } = useAuth0();

  async function addCustomHeaders(
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    const token = await getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }

  function errorResponseHandler(error: AxiosError<ApiErrorResponse>) {
    const statusCode = error.response.status;
    let wasHandled = false;

    if (customErrorHandlers.has(statusCode)) {
      wasHandled = customErrorHandlers.get(statusCode)(
        error
        // dispach,
        // getState
      );
    }
    if (!wasHandled && DefaultApiErrorHandlers.has(statusCode)) {
      wasHandled = DefaultApiErrorHandlers.get(statusCode)(
        error
        // dispach,
        // getState
      );
    }
    if (!wasHandled) {
      console.error(
        'Api error was not handled.',
        '\n',
        { url: error.config.url },
        '\n',
        { 'status code': statusCode },
        '\n',
        { 'error codes': error.response.data.errorCodes }
      );
    }

    return Promise.reject(error);
  }

  instance.interceptors.request.use(
    (config) => addCustomHeaders(config),
    (error) => error
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => errorResponseHandler(error)
  );

  return instance;
}
