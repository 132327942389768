import { AxiosError } from 'axios';
import { AppDispatch, RootState } from '../../../state/Store';
import { ApiErrorResponse } from './api-error-response';

export type ApiErrorHandler = (
  error: AxiosError<ApiErrorResponse>,
  dispatch?: AppDispatch,
  getState?: () => RootState
) => boolean;

export const DefaultApiErrorHandlers: Map<number, ApiErrorHandler> = new Map([
  [400, handle400],
  [401, handle401],
  [403, handle403],
  [404, handle404],
  [500, handle500]
]);

function handle400(error: AxiosError<ApiErrorResponse>, _dispach: AppDispatch) {
  console.error('400', error);
  return true;
}

function handle401(error: AxiosError<ApiErrorResponse>, _dispach: AppDispatch) {
  console.error('401', error);
  return true;
}

function handle403(error: AxiosError<ApiErrorResponse>, _dispach: AppDispatch) {
  console.error('403', error);
  return true;
}

function handle404(_, _dispach: AppDispatch) {
  console.error('404');
  return true;
}

function handle500(_, _dispach: AppDispatch) {
  console.error('500');
  return true;
}
